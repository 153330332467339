body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center; }
  .App h1 {
    color: #3EB7C5; }

#login {
  border: 1px solid #e0e0e0;
  padding: 36px 54px 45px;
  margin: 54px auto;
  max-width: 356px; }

.form-row {
  text-align: left;
  margin-bottom: 18px; }
  .form-row label {
    display: block;
    font-weight: 500;
    margin-bottom: 3px;
    font-size: 16px; }
  .form-row input {
    font-size: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    padding: 0 9px;
    width: 100%;
    height: 36px;
    transition: border-color .3s ease-out; }
    .form-row input:hover {
      border-color: #3EB7C5; }
    .form-row input:focus {
      box-shadow: 0 0 4px 0 rgba(62, 183, 197, 0.8); }

.error {
  color: red;
  margin-bottom: 15px; }

#resident-selector {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0 5px;
  list-style-type: none; }
  #resident-selector h2 {
    margin: 0; }
  #resident-selector li {
    margin: 10px;
    -webkit-flex-basis: 10%;
            flex-basis: 10%;
    border: 1px solid #ACE5EC;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 10px; }
    #resident-selector li.selected {
      background-color: #F3FFFF; }
  #resident-selector img {
    border-radius: 10px; }

.photos {
  padding: 0 5px;
  list-style-type: none; }
  .photos img {
    max-width: 100%;
    height: auto; }

ul.pagination {
  -webkit-justify-content: center;
          justify-content: center; }

