#resident-selector {
    display: flex;
    justify-content: center;
    padding: 0 5px;
    list-style-type: none;
    h2 {
        margin: 0; }
    li {
        margin: 10px;
        flex-basis: 10%;
        border: 1px solid #ACE5EC;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 10px;
        &.selected {
            background-color: #F3FFFF; } }
    img {
        border-radius:  10px; } }
