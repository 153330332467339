#login {
    border: 1px solid #e0e0e0;
    padding: 36px 54px 45px;
    margin: 54px auto;
    max-width: 356px; }

.form-row {
    text-align: left;
    margin-bottom: 18px;
    label {
        display: block;
        font-weight: 500;
        margin-bottom: 3px;
        font-size: 16px; }
    input {
        font-size: 16px;
        border: 1px solid #e0e0e0;
        border-radius: 3px;
        padding: 0 9px;
        width: 100%;
        height: 36px;
        transition: border-color .3s ease-out;
        &:hover {
            border-color: #3EB7C5; }
        &:focus {
            box-shadow: 0 0 4px 0 rgba(#3EB7C5, .8); } } }

.error {
    color: red;
    margin-bottom: 15px; }
